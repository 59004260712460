/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import '../css/bootstrap.css';
import '../css/component.css';
import '../css/all.css';
import '../css/font-awesome.min.css';
import '../css/style.css';
//import '../css/dataTables.bootstrap.min.css';
import '../css/tabs.css';
import '../css/timeline.css';
import '../css/chartist-init.css';
import '../css/chartist.min.css';
import '../css/chartist-plugin-tooltip.css';
import '../css/et-line-font.css';
//import '../css/tailwind.css';
//import '../css/tooltip.css';
import '../css/toastr.css';
import '../css/select2.css';
import '../css/bootstrap-switch.css';
import swal from 'sweetalert';
// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $, { data } from 'jquery';

//import Vue from 'vue'
//import App from './App.vue'
//
//new Vue({
//    el: ".content-wrapper",
//    components: {App},
//})

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');